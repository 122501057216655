<template>
  <div style="height: 500px">
    <div style="display: flex;justify-content: space-between">
      <div style="width: 200px;text-align: center;">
        <div v-for="(item, i) in bdsts">
          <div style="margin-top: 10px">
            <el-check-tag type="info" @change="onChange(item.bdst, item.bdstname)">{{ item.bdstname }}</el-check-tag>
          </div>
        </div>
      </div>
      <div style="position: absolute;left: 40%">
        <div>
          <el-tag style="margin-right: 200px">{{ departname }}</el-tag>
        </div>
        <div v-if="xxshow" style="position: absolute;left: -80%;margin-top: 30px;">
          <vxe-table
              border
              resizable
              :align="allAlign"
              :data="tableData"
              size="small"
              highlight-hover-row
              ref="xTable1"
              :export-config="{}"
          >
            <vxe-column field="departmentname" width="260" title="部门" show-header-overflow show-overflow="title"></vxe-column>
            <vxe-column title="操作" width="260" show-overflow>
              <template #default="{ row }">
                <el-button type="primary" size="mini" @click="showpeo(row)">查看人员</el-button>
              </template>
            </vxe-column>
          </vxe-table>
          <vxe-pager
              :current-page="tablePage1.currentPage"
              :page-size="tablePage1.pageSize"
              :total="tablePage1.totalResult"
              @page-change="handlePageChange1">
          </vxe-pager>
        </div>
        <div v-if="!xxshow" style="position: absolute;left: -120%;margin-top: 30px;">
          <vxe-table
              border
              resizable
              :align="allAlign"
              :data="tableData1"
              size="small"
              highlight-hover-row
              ref="xTable1"
              :export-config="{}"
          >
            <vxe-column field="name" width="100" title="姓名" show-header-overflow show-overflow="title"></vxe-column>
            <vxe-column field="tel" width="200" title="联系方式" show-header-overflow show-overflow="title"></vxe-column>
            <vxe-column field="departmentname" width="150" title="现单位" show-header-overflow show-overflow="title">{{ departname }}</vxe-column>
            <vxe-column field="depart" width="150" title="部门" show-header-overflow show-overflow="title"></vxe-column>
            <vxe-column field="posion" width="150" title="职位" show-header-overflow show-overflow="title"></vxe-column>
            <vxe-column title="操作" width="460" show-overflow>
              <template #default="{ row }">
                <el-button type="primary" size="mini" @click="sq(row)">授权</el-button>
	              <el-button type="primary" size="mini" @click="sqMini(row)">小程序授权</el-button>
                <el-button type="warning" size="mini" @click="edituser(row)">修改</el-button>
                <el-popconfirm
                    confirm-button-text="确认"
                    cancel-button-text="取消"
                    icon="el-icon-info"
                    icon-color="red"
                    title="是否确认删除？"
                    @confirm="deluser(row)"
                >
                  <template #reference>
                    <el-button type="danger" size="mini">删除</el-button>
                  </template>
                </el-popconfirm>

              </template>
            </vxe-column>
          </vxe-table>
          <vxe-pager
              :current-page="tablePage2.currentPage"
              :page-size="tablePage2.pageSize"
              :total="tablePage2.totalResult"
              @page-change="handlePageChange2">
          </vxe-pager>
        </div>
      </div>
    </div>
    <div v-if="!xxshow" style="position: absolute;right: 30px;bottom: 5px;z-index: 5">
      <el-button @click="xxshow = true">返回</el-button>
    </div>
    <div>
      <el-dialog v-model="dialogFormVisible" title="授权">
        <div v-for="(item, i) in recouses">
          <el-tag type="danger">{{ item.name }}</el-tag>
          <el-checkbox-group v-for="(item1, i) in recson" v-model="selbe" style="margin-left: 30px">
            <el-checkbox v-if="item1.parentId === item.id" :label="item1.id">{{ item1.name }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取消</el-button>
            <el-button type="primary" @click="saveqx">保存</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
<!--    修改用户-->
    <div>
      <el-dialog v-model="dialogFormVisible1" title="修改用户信息">
        <div style="text-align: center">
          <el-tag type="info" style="margin-right: 5px">&emsp;姓&emsp;&emsp;名&emsp;</el-tag>
          <el-input style="width: 250px" size="small" v-model="form.name" @input="getdepos1"></el-input>
        </div>
        <div  style="margin-top: 30px;text-align: center">
          <el-tag type="info" style="margin-right: 5px">&emsp;联系方式&emsp;</el-tag>
          <el-input style="width: 250px" size="small" v-model="form.tel"></el-input>
        </div>
        <div style="margin-top: 30px;text-align: center">
          <el-tag type="info" style="margin-right: 5px">&emsp;部&emsp;&emsp;门&emsp;</el-tag>
          <el-select style="width: 250px" v-model="form.depart" size="small" clearable placeholder="请选择">
            <el-option
                v-for="item in depart"
                :key="item.id"
                :label="item.departmentname"
                :value="item.departmentname"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-top: 30px;text-align: center">
          <el-tag type="info" style="margin-right: 5px">&emsp;职&emsp;&emsp;位&emsp;</el-tag>
          <el-select style="width: 250px" v-model="form.posion" size="small" clearable placeholder="请选择" @change="getsmstype">
            <el-option
                v-for="item in posion"
                :key="item.id"
                :label="item.postname"
                :value="item.postname"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-top: 30px;text-align: center">
          <el-tag type="info" style="margin-right: 5px">接收短信类型</el-tag>
          <el-select style="width: 250px" v-model="form.wher" size="small" clearable placeholder="请选择">
            <el-option
                v-for="item in systypes"
                :key="item.id"
                :label="item.typename"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogFormVisible1 = false">取消</el-button>
            <el-button type="primary" @click="saveuser">保存</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
	<!--  微信小程序授权-->
	  <div>
		  <el-dialog v-model="dialogFormVisibleWxMini" title="微信小程序授权">
			  <div>
				  <el-checkbox-group v-model="selWxBe">
					  <el-checkbox v-model="checked1" label="1" size="large" >车辆定位</el-checkbox>
					  <el-checkbox v-model="checked2" label="3" size="large" >专家咨询</el-checkbox>
					  <el-checkbox v-model="checked3" label="2" size="large" >物资出入库</el-checkbox>
					  <el-checkbox v-model="checked4" label="5" size="large" >设备出入库</el-checkbox>
				  </el-checkbox-group>

			  </div>
			  <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogWxCancel">取消</el-button>
            <el-button type="primary" @click="saveWxSq">保存</el-button>
          </span>
			  </template>
		  </el-dialog>
	  </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {Decrypt, Encrypt} from "@/utils/secret";
import {mapGetters} from "vuex";
import Head from "@/views/header";
import Adduserone from "@/views/admin/adduserone";
import Addgquser from "@/views/admin/addgquser";
import Organize from "@/views/admin/organize";
export default {
  components: {Adduserone, Addgquser},
  inject: ['reload'],
  name: "adduserone",
  data(){
    return{
      bdsts: [],
      xxshow: true,
      dialogFormVisible: false,
      dialogFormVisible1: false,
      formLabelWidth: '120px',
      departname: '',
      departnamechil: '',
      allAlign: 'center',
      tableData: [],
      tableData1: [],
      tablePage1: {
        currentPage: 1,
        pageSize: 5,
        totalResult: 0
      },
      tablePage2: {
        currentPage: 1,
        pageSize: 5,
        totalResult: 0
      },
      type: '',
      editid: '',
      rowflag: [],
      selbe: [],
      userid :'',
      recouses: [],
      recson: '',
      systype: [],
      form:{
        name: '',
        tel: '',
        depart: '',
        posion: '',
        wher: '',
        userid: '',
        leader: '',
        bdst: ''
      },
      systypes: [],
      depart: [],
      posion: [],
      dialogaddJz: false,
	    wx_permission: "",
	    dialogFormVisibleWxMini: false,
	    checked1: false,
	    checked2: false,
	    checked3: false,
	    checked4: false,
	    selWxBe: []
    }
  },
  computed: {
    ...mapGetters(['GET_MENU']),
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_USERID']),
    ...mapGetters(['GET_DEPART']),
    ...mapGetters(['GET_BDST']),
  },
  created() {
    this.axios.get('/other/exzxzz', (response) => {
      this.bdsts = response.obj;
      this.onChange(response.obj[0].bdst, response.obj[0].bdstname);
    },{
      bdst: this.GET_BDST
    });
  },
  methods:{
    onChange(bdst, val){
      if (this.xxshow){
        this.type = bdst;
        this.getdepart(bdst);
        this.departname = val;
      }else{
        ElMessage.error('请先点击返回')
      }
    },
    getdepart(val){
      this.axios.get('/userinfo/getdepart', (response) => {
        this.tablePage1.totalResult = response.obj.length
        this.tableData = response.obj.slice((this.tablePage1.currentPage - 1) * this.tablePage1.pageSize, this.tablePage1.currentPage * this.tablePage1.pageSize);
      },{
        type: val
      })
    },
    //分页
    handlePageChange1 ({ currentPage, pageSize }) {
      this.tablePage1.currentPage = currentPage;
      this.tablePage1.pageSize = pageSize;
      this.getdepart(this.type);
    },
    handlePageChange2 ({ currentPage, pageSize }) {
      this.tablePage2.currentPage = currentPage;
      this.tablePage2.pageSize = pageSize;
      this.showpeo(this.rowflag);
    },
    // 查看部门人员
    showpeo(row){
      this.rowflag = row;
      this.xxshow = false;
      sessionStorage.setItem('xxshow', 'false');
      this.departnamechil = row.departmentname;
      this.axios.post('/userinfo/getuserinfo', (response) => {
        this.tablePage2.totalResult = response.obj.length
        this.tableData1 = response.obj.slice((this.tablePage2.currentPage - 1) * this.tablePage2.pageSize, this.tablePage2.currentPage * this.tablePage2.pageSize);
        for (let i = 0; i < this.tableData1.length; i++){
          this.tableData1[i].tel = Decrypt(this.tableData1[i].tel);
        }
      },{
        bdst: row.type,
        depart: row.departmentname
      })
    },
  //  用户授权
    sq(row){
      this.selbe = [];
      this.userid = row.id;
      this.axios.get("/userinfo/getrec", (response) => {
        this.recouses =  response.obj;
        this.dialogFormVisible =  true;
        this.axios.get("/userinfo/getrecson", (response) => {
          this.recson = response.obj
          this.axios.post("/userinfo/getuqx", (response)  =>  {
            for (let i = 0; i < response.obj.length; i++){
              this.selbe[i] = response.obj[i].resouceid
            }
            this.selaf = this.selbe
          },{
            userid: this.userid
          })
        })
      })
    },
    saveqx(){
      if (this.selaf.length === this.selbe.length){
        ElMessage({
          type: 'danger',
          message: '权限未发生变化',
        })
      }else {
        this.axios.post("/userinfo/upqx", (response) => {
          if (response.obj){
            ElMessage({
              message: '授权成功！',
              type: 'success',
            })
            this.dialogFormVisible = false;
          }else {
            ElMessage({
              message: '授权失败',
              type: 'warning',
            })
          }
        }, {
          userid: this.userid,
          ids: this.selbe
        })
      }
    },
    deluser(row){
      this.axios.post('/userinfo/deluserinfo', (response) => {
        if (response.obj){
          this.xxshow = true;
          ElMessage.success({
            message: '删除成功',
            type: 'success',
          });
        }
      },{
        userid: row.id
      })
    },
    edituser(row){
      console.log(row)
      this.dialogFormVisible1 = true;
      this.axios.get('/userinfo/getdepart', (response) => {
        this.depart = response.obj;
      },{
        type: this.type
      });
      this.axios.get('/userinfo/getpos', (response) => {
        this.posion = response.obj;
      },{
        type: this.type
      });
      this.axios.get('/userinfo/getsmstype', (response) => {
        this.systypes = response.obj
      });
      this.form.id = row.id
      this.form.name = row.name;
      this.form.tel = row.tel;
      this.form.posion = row.posion;
      this.form.depart = row.depart;
      this.form.wher = row.wher;
      this.form.bdst = row.bdst;
      this.form.leader = row.leader;
    },
    saveuser(){
      this.form.tel = Encrypt(this.form.tel);
      this.axios.post('/userinfo/updatauser', (response) => {
        if (response.obj){
          this.xxshow = true;
          this.dialogFormVisible1 = false;
          if (sessionStorage.getItem('xxshow')){
            this.showpeo(this.rowflag);
          }
          ElMessage.success({
            message: '修改成功',
            type: 'success',
          });
        }
      },this.form)
    },
	  sqMini(row){
			console.log(row)
			this.wx_permission = row.id
		  this.dialogFormVisibleWxMini = true
	  },
	  dialogWxCancel(){
		  this.selWxBe = []
		  this.wx_permission = ''
			this.checked1 = false
		  this.checked2 = false
		  this.checked3 = false
		  this.checked4 = false
		  this.dialogFormVisibleWxMini = false
	  },
	  saveWxSq(){
			if (this.selWxBe.length === 0){
				ElMessage.error('请选择小程序权限!')
			}else {
				let per = '';
				for (let i = 0; i < this.selWxBe.length; i++){
					per = per + this.selWxBe[i] + ','
				}
				this.axios.post('/userinfo/updataWxmini', (response) => {
					if (response.obj){
						this.selWxBe = []
						this.wx_permission = ''
						this.checked1 = false
						this.checked2 = false
						this.checked3 = false
						this.checked4 = false
						this.dialogFormVisibleWxMini = false
						ElMessage.success('授权成功!')
					}else {
						ElMessage.error('授权失败，请联系科信部!')
					}
				},{
					user_id: this.wx_permission,
					permission: per
				})
			}
	  }


  }
}
</script>

<style scoped>

</style>

