<template>
  <div style="height: 500px">
    <div style="display: flex;justify-content: space-between">
      <div style="width: 200px;text-align: center;">
        <div v-for="(item, i) in bdsts">
          <div style="margin-top: 10px">
            <el-check-tag type="info" @change="onChange(item.bdst, item.bdstname)">{{ item.bdstname }}</el-check-tag>
          </div>
        </div>
      </div>
      <div v-if="xxshow" style="position: absolute;top: 40%;left: 50%">
        <el-button type="primary" plain @click="dialogFormVisible = true">新增工区</el-button>
      </div>
      <div v-if="!xxshow" style="position: absolute;left: 40%">
        <div>
          <el-tag style="margin-right: 200px">{{ departname }}</el-tag>
        </div>
        <div style="position: absolute;left: -80%;margin-top: 30px;">
          <vxe-table
              border
              resizable
              :align="allAlign"
              :data="tableData"
              size="small"
              highlight-hover-row
              ref="xTable1"
              :export-config="{}"
          >
            <vxe-column field="departmentname" width="260" title="部门" show-header-overflow show-overflow="title"></vxe-column>
            <vxe-column title="操作" width="260" show-overflow>
              <template #default="{ row }">
                <el-button type="primary" size="mini" @click="editdep(row)">修改</el-button>
                <el-popconfirm
                    confirm-button-text="确认"
                    cancel-button-text="取消"
                    icon="el-icon-info"
                    icon-color="red"
                    title="是否确认删除此部门？"
                    @confirm="del(row)"
                >
                  <template #reference>
                    <el-button type="danger" size="mini">删除</el-button>
                  </template>
                </el-popconfirm>
              </template>
            </vxe-column>
          </vxe-table>
          <vxe-pager
              :current-page="tablePage1.currentPage"
              :page-size="tablePage1.pageSize"
              :total="tablePage1.totalResult"
              @page-change="handlePageChange1">
          </vxe-pager>
        </div>
        <div style="position: absolute;right: -100%;top: 260%">
          <div style="margin-bottom: 50px">
            <el-button type="primary" plain @click="updatebdstname">修改名称</el-button>
          </div>
          <div style="margin-bottom: 50px">
            <el-popconfirm
                confirm-button-text="确认"
                cancel-button-text="取消"
                icon="el-icon-info"
                icon-color="red"
                title="是否确认删除此组织？"
                @confirm="confirmEvent"
            >
              <template #reference>
                <el-button type="danger" plain>删除组织</el-button>
              </template>
            </el-popconfirm>
          </div>
          <div>
            <el-button type="primary" plain @click="openadddep">新增部门</el-button>
          </div>
        </div>
        <div style="position: fixed;right: 100px;bottom: 50px">
          <el-button type="primary" plain @click="xxshow = true">返回</el-button>
        </div>
      </div>

    </div>
    <el-dialog v-model="dialogFormVisible" title="新增工区">
      <el-form :model="form">
        <el-form-item label="标段名称" :label-width="formLabelWidth">
          <el-input v-model="form.bdstname" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="addbdst">确认</el-button>
      </span>
      </template>
    </el-dialog>
<!--修改组织名称-->
    <el-dialog v-model="dialogFormVisible1" title="修改组织名称">
      <el-form :model="form">
        <el-form-item label="组织名称" :label-width="formLabelWidth">
          <el-input v-model="form.upbdstname" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible1 = false">取消</el-button>
        <el-button type="primary" @click="editbdst">确认</el-button>
      </span>
      </template>
    </el-dialog>
<!--    修改组织部门名称-->
    <el-dialog v-model="dialogFormVisible2" title="修改部门名称">
      <el-form :model="form">
        <el-form-item label="部门名称" :label-width="formLabelWidth">
          <el-input v-model="form.updepname" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取消</el-button>
        <el-button type="primary" @click="editdepart">确认</el-button>
      </span>
      </template>
    </el-dialog>
<!--    新增部门-->
    <el-dialog v-model="dialogFormVisible3" title="新增部门">
      <el-form :model="form">
        <el-form-item label="部门名称" :label-width="formLabelWidth">
          <el-input v-model="form.adddepname" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible3 = false">取消</el-button>
        <el-button type="primary" @click="adddepart">确认</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";

export default {
  inject: ['reload'],
  name: "organize",
  data(){
    return{
      bdsts: [],
      xxshow: true,
      dialogFormVisible: false,
      dialogFormVisible1: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      form: {
        bdstname: '',
        upbdstname: '',
        updepname: '',
        adddepname: ''
      },
      formLabelWidth: '120px',
      departname: '',
      allAlign: 'center',
      tableData: [],
      tablePage1: {
        currentPage: 1,
        pageSize: 5,
        totalResult: 0
      },
      type: '',
      editid: ''
    }
  },
  computed: {
    ...mapGetters(['GET_MENU']),
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_USERID']),
    ...mapGetters(['GET_DEPART']),
    ...mapGetters(['GET_BDST']),
  },
  created() {
    this.axios.get('/other/exzxzz', (response) => {
      this.bdsts = response.obj;
      //this.onChange(response.obj[0].bdst, response.obj[0].bdstname);
    },{
      bdst: this.GET_BDST
    });
    if (sessionStorage.getItem('bdst') && sessionStorage.getItem('val')){
      this.onChange(sessionStorage.getItem('bdst'), sessionStorage.getItem('val'));
    }else{
      this.axios.get('/other/exzxzz', (response) => {
        this.bdsts = response.obj;
        this.onChange(response.obj[0].bdst, response.obj[0].bdstname);
      },{
        bdst: this.GET_BDST
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    // 在离开此路由之后清除保存的状态（我的需求是只需要在当前tab页操作刷新保存状态，路由切换之后不需要保存）
    // 根据个人需求决定清除的时间
    sessionStorage.removeItem('currentTab')
    next()
  },
  methods:{
    onChange(bdst, val){
      this.type = bdst;
      this.xxshow = false;
      this.getdepart(bdst);
      this.departname = val;
      sessionStorage.setItem('bdst', bdst);
      sessionStorage.setItem('val', val);
    },
    getdepart(val){
      this.axios.get('/userinfo/getdepart', (response) => {
        this.tablePage1.totalResult = response.obj.length
        this.tableData = response.obj.slice((this.tablePage1.currentPage - 1) * this.tablePage1.pageSize, this.tablePage1.currentPage * this.tablePage1.pageSize);
      },{
        type: val
      })
    },
    addbdst(){
      let bdst1 = this.form.bdstname.slice(0, 1);
      let bdst2 = this.form.bdstname.slice(2, 3);
      let bdst = bdst1 + bdst2
      this.axios.post('/userinfo/addbdst', (response) => {
        if (response.obj){
          this.dialogFormVisible = false;
          this.reload();
          ElMessage.success({
            message: '新增成功',
            type: 'success',
          });
        }
      },{
        bdstname: this.form.bdstname,
        bdst: bdst
      })
    },
    //分页
    handlePageChange1 ({ currentPage, pageSize }) {
      this.tablePage1.currentPage = currentPage;
      this.tablePage1.pageSize = pageSize;
      this.getdepart(this.type);
    },
  //  删除部门
    del(row){
      this.axios.post('/userinfo/deldep', (response) => {
        if (response.obj){
          this.reload();
          ElMessage.success({
            message: '删除成功',
            type: 'success',
          });
        }
      },{
        id: row.id * 1
      })
    },
  //  修改组织名称，弹框和回显
    updatebdstname(){
      this.dialogFormVisible1 = true;
      this.form.upbdstname = this.departname;
    },
  //  修改组织名称
    editbdst(){
      this.axios.post('/userinfo/editdepart', (response) => {
        if (response.obj){
          this.reload();
          ElMessage.success({
            message: '修改成功',
            type: 'success',
          });
        }
      },{
        bdstname: this.form.upbdstname,
        bdst: this.type * 1
      })
    },
  //  删除组织
    confirmEvent(){
      this.axios.post('/userinfo/deldepos', (response) => {
        if (response.obj){
          this.reload();
          ElMessage.success({
            message: '删除成功',
            type: 'success',
          });
        }
      },{
        type: this.type * 1
      })
    },
  //  修改部门名称
    editdep(row){
      console.log(row);
      this.editid = row.id;
      this.form.updepname = row.departmentname
      this.dialogFormVisible2 = true;
    },
    editdepart(){
      this.axios.post('/userinfo/updatadep', (response) => {
        if (response.obj){
          this.reload();
          ElMessage.success({
            message: '修改成功',
            type: 'success',
          });
        }
      },{
        id: this.editid * 1,
        depname: this.form.updepname
      })
    },
  //  新增部门
    openadddep(){
      this.dialogFormVisible3 = true;
    },
    adddepart(){
      this.axios.post('/userinfo/adddep', (response) => {
        if (response.obj){
          this.reload();
          ElMessage.success({
            message: '增加成功',
            type: 'success',
          });
        }
      },{
        departmentname : this.form.adddepname,
        type: this.type * 1
      })
    }

  }
}
</script>

<style scoped>

</style>
