<template>
  <div>
    <Head></Head>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }" @click="reall">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">组织管理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div id="forms_div2">
      <el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
        <el-tab-pane label="组织管理" name="a">
          <Organize></Organize>
        </el-tab-pane>
        <el-tab-pane label="用户管理" name="b">
          <div v-if="initdiv" style="display: flex;justify-content: center;height: 500px;">
            <div id="xz1" style="margin-right: 200px" @click="addpeos">
              <img :src="adduser" alt="" style="width: 200px;height: 150px;"/>
              <div style="position: absolute;right: 120px;top: -30px">添加用户</div>
            </div>
            <div id="xz2" @click="addsq">
              <img :src="usersq" alt="" style="width: 200px;height: 150px;"/>
              <div style="position: absolute;right: 120px;top: -30px">用户授权</div>
            </div>
          </div>
          <div v-if="addpeo">
            <div style="margin-left: 50px">
              <el-button size="mini" @click="goback1">返回</el-button>
            </div>
            <div v-if="this.GET_BDST === '1'" style="display: flex;justify-content: space-between;margin-top: 10px">
              <Adduserone></Adduserone>
              <Addgquser></Addgquser>
            </div>
            <div v-else style="width: 90%;margin-left: 50px">
              <div style="margin-right: 200px">
                <div>
                  <div id="addpeo">
                    <div style="margin-left: 20px;line-height: 50px;text-align: center">
                      工区人员
                    </div>
                  </div>
                </div>
                <div id="addpeo_div2">
                  <div style="margin-top: 50px;">
                    <el-tag type="info" style="margin-right: 5px">&emsp;姓&emsp;&emsp;名&emsp;</el-tag>
                    <el-input style="width: 250px" size="small" v-model="form.name" @input="getdepos2"></el-input>
                  </div>
                  <div style="margin-top: 20px;">
                    <el-tag type="info" style="margin-right: 5px">&emsp;联系方式&emsp;</el-tag>
                    <el-input style="width: 250px" size="small" v-model="form.tel"></el-input>
                  </div>
                  <div style="margin-top: 20px;">
                    <el-tag type="info" style="margin-right: 5px">&emsp;标&emsp;&emsp;段&emsp;</el-tag>
                    <el-select style="width: 250px" v-model="form.bdsts" size="small" clearable placeholder="请选择" @change="getdepos3">
                      <el-option
                          v-for="item in bd"
                          :key="item.bdst"
                          :label="item.bdstname"
                          :value="item.bdst"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div style="margin-top: 20px;">
                    <el-tag type="info" style="margin-right: 5px">&emsp;部&emsp;&emsp;门&emsp;</el-tag>
                    <el-select style="width: 250px" v-model="form.departs" size="small" clearable placeholder="请选择">
                      <el-option
                          v-for="item in departgq"
                          :key="item.id"
                          :label="item.departmentname"
                          :value="item.departmentname"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div style="margin-top: 20px;">
                    <el-tag type="info" style="margin-right: 5px">&emsp;职&emsp;&emsp;位&emsp;</el-tag>
                    <el-select style="width: 250px" v-model="form.posions" size="small" clearable placeholder="请选择" @change="getsmstype">
                      <el-option
                          v-for="item in posiongq"
                          :key="item.id"
                          :label="item.postname"
                          :value="item.postname"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div style="margin-top: 20px;">
                    <el-tag type="info" style="margin-right: 5px">接收短信类型</el-tag>
                    <el-select style="width: 250px" v-model="form.systype" size="small" clearable placeholder="请选择">
                      <el-option
                          v-for="item in systypes"
                          :key="item.id"
                          :label="item.typename"
                          :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div style="float: right;margin-top: 30px">
                    <el-button type="primary" size="small" @click="upuser">提交</el-button>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div v-if="sq">
            <Sq></Sq>
            <div style="position: absolute;right: 30px;bottom: 5px">
              <el-button @click="goback2">返回</el-button>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Head from "@/views/header";
import Adduserone from "@/views/admin/adduserone";
import Addgquser from "@/views/admin/addgquser";
import Organize from "@/views/admin/organize";
import Sq from  "@/views/admin/sq"
import {mapGetters} from "vuex";
import {ElMessage} from "element-plus";
import {Encrypt} from "@/utils/secret";

export default {
  components: {Head, Adduserone, Addgquser, Organize, Sq},
  name: "adduser",
  data() {
    return {
      form: {
        name: '',
        tel: '',
        departs: '',
        posions: '',
        bdsts: [],
        systype: '',
        rockid: ''
      },
      departgq: [],
      posiongq: [],
      bd: [],
      systypes: [],
      activeName: 'a',
      initdiv: true,
      addpeo: false,
      sq: false,
      adduser: 'https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/adduser.png',
      usersq: 'https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/usersq.png',
      rocks: [],

    }
  },
  computed: {
    ...mapGetters(['GET_BDST']),
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_USERID']),
    ...mapGetters(['GET_DEPART']),
  },
  created() {
    console.log('参数==' + this.$route.params.key)
    if (!this.$route.params.key) {
      this.$router.push("/manage")
    }
  },
  mounted() {
    let name = sessionStorage.getItem('currentTab')
    console.log(name)
    // 判断是否存在currentTab，即tab页之前是否被点击切换到别的页面
    if (name) {
      this.activeName = name
    }
  },
  beforeRouteLeave(to, from, next) {
    // 在离开此路由之后清除保存的状态（我的需求是只需要在当前tab页操作刷新保存状态，路由切换之后不需要保存）
    // 根据个人需求决定清除的时间
    sessionStorage.removeItem('currentTab')
    next()
  },
  methods: {
    reall(){
      sessionStorage.removeItem('currentTab');
      sessionStorage.removeItem('bdst');
      sessionStorage.removeItem('val');
      sessionStorage.removeItem('xxshow');
    },
    handleClick(tab, event) {
      console.log(tab)
      // 点击tab后触发事件，修改显示页面，将状态保存在sessionStorage里面
      sessionStorage.setItem('currentTab', tab.props.name)
    },
    addpeos(){
      this.initdiv = false;
      this.addpeo = true;
    },
    addsq(){
      this.initdiv = false;
      this.sq = true;
    },
    goback1(){
      this.initdiv = true;
      this.addpeo = false;
    },
    goback2(){
      this.initdiv = true;
      this.sq = false;
    },
    getdepos2(){
      this.getbdst1();
    },
    getdepos3(){
      this.getdepartgq(this.form.bdsts);
      this.getposiongq(this.form.bdsts);
    },
    getdepartgq(val){
      this.axios.get('/userinfo/getdepart', (response) => {
        this.departgq = response.obj;
      },{
        type: val
      })
    },
    getposiongq(val){
      this.axios.get('/userinfo/getpos', (response) => {
        this.posiongq = response.obj;
      },{
        type: val
      })
    },
    getbdst1(){
      this.axios.get('/other/exzx', (response) =>  {
        this.bd = response.obj
      });
    },
    getsmstype(){
      this.axios.get('/userinfo/getsmstype', (response) => {
        this.systypes = response.obj
      });

    },
    upuser(){
      if (this.form.name === ''){
        ElMessage.error('请输入姓名！')
      }else if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.form.tel)){
        ElMessage.error('请填写正确的手机号')
      }else {
        const tel1 = Encrypt(this.form.tel);
        const pas = Encrypt('Zt12jcztl');
        this.axios.post("/userinfo/register", (response) => {
          if (response.obj){
            ElMessage({
              message: '添加成功',
              type: 'success',
            })
            this.form = []
          }else {
            ElMessage.error('该手机号已注册，请直接登录！')
          }
        }, {
          tel: tel1,
          name: this.form.name,
          leader: pas,
          depart: this.form.departs,
          posion: this.form.posions,
          bdst: this.form.bdsts,
          wher: this.form.systype
        })
      }
    }
  }
}
</script>

<style scoped>
#admindiv1 {
  background-color: white;
  display: flex;
  width: 90%;
  height: 40px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  justify-content: space-between;
}

#forms_div2 {
  border-radius: 5px;
  margin: 0 auto;
  background-color: white;
  width: 90%;
  padding-top: 20px;
  padding-bottom: 20px
}
#xz1{
  width: 200px;
  height: 150px;
  position: absolute;
  top: 50%;
  left: 30%;
  transform:translate(-50%,-50%);
  text-align: center;
  line-height: 150px;
  border-radius: 5px;
}
#xz2{
  width: 200px;
  height: 150px;
  position: absolute;
  top: 50%;
  left: 60%;
  transform:translate(-50%,-50%);
  text-align: center;
  line-height: 150px;
  border-radius: 5px;
}
#addpeo{
  width: 120%;
  height: 50px;
  background-color: #dcd6ff;
}
#addpeo_div2{
  width: 120%;
  background-color: white;
  padding-left: 80px;
  text-align: center;
}
</style>
