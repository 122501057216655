<template>
  <div  style="margin-left: 20px">
    <div>
      <div id="addpeo">
        <div style="margin-left: 20px;line-height: 50px;text-align: center">
          项目公司人员
        </div>
      </div>
    </div>
    <div id="addpeo_div2">
      <div style="margin-top: 30px;">
        <el-tag type="info" style="margin-right: 5px">&emsp;姓&emsp;&emsp;名&emsp;</el-tag>
        <el-input style="width: 250px" size="small" v-model="form.name" @input="getdepos1"></el-input>
      </div>
      <div  style="margin-top: 30px;">
        <el-tag type="info" style="margin-right: 5px">&emsp;联系方式&emsp;</el-tag>
        <el-input style="width: 250px" size="small" v-model="form.tel"></el-input>
      </div>
      <div style="margin-top: 30px;">
        <el-tag type="info" style="margin-right: 5px">&emsp;部&emsp;&emsp;门&emsp;</el-tag>
        <el-select style="width: 250px" v-model="form.departs" size="small" clearable placeholder="请选择" @change="getflag(form.departs)">
          <el-option
              v-for="item in departjz"
              :key="item.id"
              :label="item.departmentname"
              :value="item.departmentname"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-top: 30px;">
        <el-tag type="info" style="margin-right: 5px">&emsp;职&emsp;&emsp;位&emsp;</el-tag>
        <el-select style="width: 250px" v-model="form.posions" size="small" clearable placeholder="请选择" @change="getsmstype">
          <el-option
              v-for="item in posionjz"
              :key="item.id"
              :label="item.postname"
              :value="item.postname"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-top: 20px;">
        <el-tag type="info" style="margin-right: 5px">接收短信类型</el-tag>
        <el-select style="width: 250px" v-model="form.systype" size="small" clearable placeholder="请选择">
          <el-option
              v-for="item in systypes"
              :key="item.id"
              :label="item.typename"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <div style="margin-top: 20px;">
          <el-tag type="info" style="margin-right: 5px">围岩自动判识权限</el-tag>
          <el-select style="width: 230px" v-model="form.rockid" size="small" clearable placeholder="请选择">
            <el-option
                v-for="item in rocks"
                :key="item.id"
                :label="item.scope"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div style="float: right;margin-top: 30px;">
          <el-button type="primary" size="small" @click="upuser">提交</el-button>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import {ElMessage} from "element-plus";
import {Encrypt} from "@/utils/secret";

export default {
  name: "adduserone",
  data(){
    return{
      form: {
        name: '',
        tel: '',
        departs: '',
        posions: '',
        systype: '',
        rockid: ''
      },
      form1: {
        name: '',
        tel: '',
        departs: '',
        posions: ''
      },
      options: [],
      departjz: [],
      posionjz: [],
      systypes: [],
      departflag: '',
      rocks: []
    }
  },
  created(){
    if (!this.$route.params.key){
      this.$router.push("/manage")
    }
  },
  methods:{
    getdepos1(){
      this.getdepartjz(1);
      this.getposionjz(1);
    },
    getdepartjz(val){
      this.axios.get('/userinfo/getdepart', (response) => {
        this.departjz = response.obj;
      },{
        type: val
      })
    },
    getdepartgq(val){
      this.axios.get('/userinfo/getdepart', (response) => {
        this.departgq = response.obj;
      },{
        type: val
      })
    },
    getposionjz(val){
      this.axios.get('/userinfo/getpos', (response) => {
        this.posionjz = response.obj;
      },{
        type: val
      })
    },
    getposiongq(val){
      this.axios.get('/userinfo/getpos', (response) => {
        this.posiongq = response.obj;
      },{
        type: val
      })
    },
    getsmstype(){
      this.axios.get('/userinfo/getsmstype', (response) => {
        this.systypes = response.obj;
      });
      this.axios.get('/jointRock/getType', (response) => {
        this.rocks = response.obj
      })
    },
    upuser(){
      const pas = Encrypt('Zt12jcztl');
      if (this.form.name === ''){
        ElMessage.error('请输入姓名！')
      }else if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.form.tel)){
        ElMessage.error('请填写正确的手机号')
      }else {
        const tel1 = Encrypt(this.form.tel);
        this.axios.post("/userinfo/register", (response) => {
          if (response.obj){
            ElMessage({
              message: '添加成功',
              type: 'success',
            })
            this.form = []
          }else {
            ElMessage.error('该手机号已注册，请直接登录！')
          }
        }, {
          tel: tel1,
          name: this.form.name,
          leader: pas,
          depart: this.form.departs,
          posion: this.form.posions,
          bdst: 1,
          wher: this.form.systype,
          departflag: this.departflag,
          rockid: this.form.rockid
        })
      }
    },
    getflag(val){
      this.axios.post("/userinfo/getdeflag", (response) => {
        this.departflag = response.obj.flag
        console.log(this.departflag)
      },{
        departname: val
      })
    }
  }
}
</script>

<style scoped>
#addpeo{
  width: 100%;
  height: 50px;
  background-color: #d6e8ff;
}
#addpeo_div2{
  width: 85%;
  background-color: white;
  padding-left: 70px;
}
</style>

